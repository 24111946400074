/**
 * Modifies the results page by handling button interactions and dynamically updating the DOM.
 */
export function modifyResultsPage() {
    const contactForm = document.querySelector('#contact-form.contact-form');
    if (!contactForm) return;

    const container = document.querySelector('.qsm-auto-page-row.quiz_section.quiz_begin');
    if (!container) return;

    contactForm.parentNode.removeChild(contactForm);
    container.appendChild(contactForm);

    contactForm.style.marginTop = '30px';
    contactForm.style.display = 'block';

    modifyPaginationText();
    setInterval(modifyPaginationText, 500);

    const qsmSubmitBtn = document.querySelector('.qsm-submit-btn');
    if (qsmSubmitBtn) {
        qsmSubmitBtn.addEventListener('click', function (event) {
            const observer = new MutationObserver((mutations, obs) => {
                const nextStageButton = document.getElementById('next-step');
                if (nextStageButton) {
                    nextStageButton.addEventListener('click', function (event) {
                        event.preventDefault();
                        modifyResultsPageActions();
                    });
                    obs.disconnect();
                }
            });
            observer.observe(document.body, {childList: true, subtree: true});
        });
    }

    const nextStepBtn = document.querySelector('.qmn_btn.mlw_qmn_quiz_link.mlw_next.mlw_custom_next');
    if (nextStepBtn) {
        nextStepBtn.addEventListener('click', function (event) {
            modifyPaginationText();
        });
    }

    const allNavigationButtons = document.querySelectorAll('.qmn_btn, .mlw_qmn_quiz_link, .mlw_next, .mlw_previous, .qsm-next, .qsm-previous');
    if (allNavigationButtons.length > 0) {
        allNavigationButtons.forEach(button => {
            button.addEventListener('click', function () {
                setTimeout(modifyPaginationText, 100);
            });
        });
    }
}

/**
 * Executes actions to modify the results page layout and behavior.
 */
function modifyResultsPageActions() {
    const successElement = document.getElementById('success');
    if (successElement) {
        successElement.style.display = 'none';
    }

    const contactForm = document.getElementById('contact-form');
    if (contactForm) {
        contactForm.style.display = 'block';
    }

    const inputField = document.querySelector('#contact_field_0');
    if (inputField) {
        const textarea = document.createElement('textarea');
        textarea.name = inputField.name;
        textarea.id = inputField.id;
        textarea.placeholder = inputField.placeholder;
        textarea.value = inputField.value;
        textarea.className = inputField.className;

        textarea.style.width = '100%';
        textarea.style.minHeight = '150px';
        textarea.style.padding = '10px';
        textarea.style.boxSizing = 'border-box';
        textarea.style.resize = 'vertical';

        inputField.parentNode.replaceChild(textarea, inputField);
    }
}

/**
 * Modifies pagination text display
 */
function modifyPaginationText() {
    const pageCountContainer = document.querySelector('.qsm-ultimate-page-count');
    if (pageCountContainer) {
        const pageNumSpan = pageCountContainer.querySelector('.ul_page_num');
        const pageTotalSpan = pageCountContainer.querySelector('.ul_page_total');

        if (pageNumSpan && pageTotalSpan) {
            let pageNum = pageNumSpan.textContent.trim().replace(/Pytanie\s+/i, '');
            let pageTotal = pageTotalSpan.textContent.trim().replace('out of', '/');

            pageNumSpan.textContent = 'Pytanie ' + pageNum;
            pageTotalSpan.textContent = pageTotal;
        } else {
            const text = pageCountContainer.textContent.trim();
            const match = text.match(/(\d+)\s+out of\s+(\d+)/);
            if (match) {
                pageCountContainer.innerHTML = '<span class="ul_page_num">Pytanie ' + match[1] + '</span> <span class="ul_page_total">/ ' + match[2] + '</span>';
            }
        }
        return;
    }

    const pagesCountSpan = document.querySelector('.pages_count');
    if (pagesCountSpan) {
        const text = pagesCountSpan.textContent.trim();
        const match = text.match(/(\d+)\s+out of\s+(\d+)/);
        if (match) {
            pagesCountSpan.style.display = 'block';
            pagesCountSpan.innerHTML = 'Pytanie ' + match[1] + ' / ' + match[2];
        }
    }
}

/**
 * Initializes the quiz member selection functionality.
 */
export function initQuizMembers() {
    const checkbox10 = document.querySelector('.qsm_contact_div:nth-of-type(10) input');
    const checkbox11 = document.querySelector('.qsm_contact_div:nth-of-type(11) input');

    if (!checkbox10 || !checkbox11) return;

    const checkboxes = [checkbox10, checkbox11];

    checkboxes.forEach(checkbox => {
        checkbox.checked = false;
        checkbox.classList.add('mlwRequiredText', 'qsm_required_text', 'mlwRequiredAccept');

        checkbox.addEventListener('change', function () {
            if (this.checked) {
                checkboxes.forEach(cb => {
                    if (cb === this) {
                        cb.classList.add('mlwRequiredText', 'qsm_required_text', 'mlwRequiredAccept');
                    } else {
                        cb.checked = false;
                        cb.classList.remove('mlwRequiredText', 'qsm_required_text', 'mlwRequiredAccept');
                    }
                });
            }
        });
    });

    const $contactField4 = $('#contact_field_2');
    if ($contactField4.length) {
        $contactField4.on('input', function (e) {
            let value = $(this).val().replace(/[^0-9-]/g, '');
            if (value.length === 2 && !value.includes('-')) {
                value += '-';
            }
            if (value.length > 6) {
                value = value.substring(0, 6);
            }
            $(this).val(value);
        });

        $contactField4.on('keypress', function (e) {
            const allowedKeys = [45];
            const keyCode = e.which || e.keyCode;
            if (!(keyCode >= 48 && keyCode <= 57) && !allowedKeys.includes(keyCode)) {
                e.preventDefault();
            }
        });
    }

    const $contactField5 = $('#contact_field_3');
    if ($contactField5.length) {
        $contactField5.on('keypress', function (e) {
            const allowedKeys = [43, 32];
            const keyCode = e.which || e.keyCode;
            if (!(keyCode >= 48 && keyCode <= 57) && !allowedKeys.includes(keyCode)) {
                e.preventDefault();
            }
        });
    }
}