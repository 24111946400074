import 'vanilla-cookieconsent';

const initCookieNotice = () => {
  const lang = document.documentElement.lang.slice(0, 2);
  // eslint-disable-next-line no-undef
  const cookieConsent = initCookieConsent();

  cookieConsent.run({
    current_lang: lang,
    autoclear_cookies: true,
    page_scripts: true,
    cookie_name: 'cc_cookie_nbp_pl',

    gui_options: {
      consent_modal: {
        layout: 'box',               // box/cloud/bar
        position: 'bottom right',     // bottom/middle/top + left/right/center
        transition: 'slide',           // zoom/slide
        swap_buttons: false            // enable to invert buttons
      },
    },

    onAccept: () => {
      // Define dataLayer and the gtag function.
      window.dataLayer = window.dataLayer || [];

      // eslint-disable-next-line no-undef
      function gtag () {dataLayer.push(arguments);}

      gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    },

    languages: {
      'pl': {
        consent_modal: {
          title: 'Cenimy Twoją prywatność',
          description: 'Używamy plików cookies, aby poprawić komfort przeglądania, wyświetlać spersonalizowane reklamy lub treści oraz analizować nasz ruch.' +
            ' Jeśli wyrazisz na to zgodę, będziemy używać plików cookies, aby umożliwić nam gromadzenie danych do zbiorczych statystyk w celu ulepszenia naszych usług i zapamiętania Twojego wyboru podczas przyszłych wizyt.' +
            ' Jeśli tego nie chcesz, będziemy używać plików cookies wyłącznie w celu zapamiętania Twojego wyboru na potrzeby przyszłych wizyt (tj. niezbędnych plików cookies). Aby się dowiedzieć więcej o technologii cookies, proszę kliknąć tutaj: ' +
            '<a href="/o-nbp/kontakt/regulaminy/" class="cc-link">Polityka Prywatności NBP</a>. ',
          primary_btn: {
            text: 'Akceptuj wszystkie pliki cookies',
            role: 'accept_all',
          },
          secondary_btn: {
            text: 'Zostaw tylko niezbędne pliki cookies',
            role: 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Ustawienia cookies',
          save_settings_btn: 'Zapisz obecny wybór',
          accept_all_btn: 'Akceptuj wszystkie pliki cookies',
          reject_all_btn: 'Zostaw tylko niezbędne pliki cookies',
          close_btn_label: 'Zamknij',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
          ],
          blocks: [
            {
              title: 'Wykorzystanie plików cookies',
            },
            {
              title: 'Wymagane pliki cookies',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              },
              cookie_table: [
                {
                  col1: '^visid_incap_',
                  col2: '.nbp.pl',
                  col3: '1 year 1 month',
                },
                {
                  col1: '^incap_ses_',
                  col2: '.nbp.pl',
                  col3: '1 month',
                },
                {
                  col1: 'wp-wpml_current_language',
                  col2: '.nbp.pl',
                  col3: 'Session\t',
                },
              ]
            }, {
              title: 'Analiza & Wydajność',
              toggle: {
                value: 'analytics',
                enabled: true,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga_',
                  col2: '.nbp.pl',
                  col3: '1 year 1 month',
                  is_regex: true
                },
                {
                  col1: '_ga',
                  col2: '.nbp.pl',
                  col3: '1 year 1 month',
                  is_regex: true
                },
              ]
            },
          ]
        }
      },
      'en': {
        consent_modal: {
          title: 'We care about your privacy',
          description: 'We use cookies to improve your browsing experience, display personalized ads or content, and analyze our traffic. If you consent, we will use cookies to enable us to collect data for aggregate statistics to improve our services and remember your choice for future visits. If you do not want this, we will only use cookies to remember your choice for future visits (i.e., strictly necessary cookies). To learn more about cookies technology, please click here: ' +
            '<a href="en/about-nbp/contact/nbps-regulations/" class="cc-link">NBP Privacy Policy</a>. ',
          primary_btn: {
            text: 'Accept all cookies',
            role: 'accept_all',
          },
          secondary_btn: {
            text: 'Keep only essential cookies',
            role: 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookie settings',
          save_settings_btn: 'Save current selection',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Accept necessary',
          close_btn_label: 'Close',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
          ],
          blocks: [
            {
              title: 'Cookie usage',
            },
            {
              title: 'Strictly necessary cookies',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              },
              cookie_table: [
                {
                  col1: '^visid_incap_',
                  col2: '.nbp.pl',
                  col3: '12 months 4 days',
                },
                {
                  col1: '^incap_ses_',
                  col2: '.nbp.pl',
                  col3: 'Session',
                },
                {
                  col1: 'wp-wpml_current_language',
                  col2: '.nbp.pl',
                  col3: 'Session',
                },
              ]
            }, {
              title: 'Analytics & Performance',
              toggle: {
                value: 'analytics',
                enabled: true,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga_',
                  col2: '.nbp.pl',
                  col3: '1 year 1 month',
                  is_regex: true
                },
                {
                  col1: '_ga',
                  col2: '.nbp.pl',
                  col3: '1 year 1 month',
                  is_regex: true
                },
              ]
            },
          ]
        }
      }
    }
  });
};

export { initCookieNotice };
